/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import { Loader, Panel } from 'rsuite'

import { useParams, useNavigate, Navigate } from 'react-router-dom'

import { v4 as uuid } from 'uuid'

import {
  getOrganisation,
  updateOrganisationGroups
} from '../../services/graphql/queries/organisations'
import { NotFound } from '../error-pages/NotFound'
import { PanelHeader } from '../../components'
import {
  useApi, useApiGroup, useFormState, usePageTitle, useUser
} from '../../app/hooks'
import { OrganisationGroupForm } from '../../forms/OrganisationGroupForm'
import { UserRole } from '../../types/enums'

function OrganisationGroupDetails () {
  const navigate = useNavigate()
  const { organisationId, groupId } = useParams()
  const user = useUser()
  const isAdmin = user.hasRole(UserRole.ADMIN, UserRole.OPERATOR)

  const api = useApiGroup({
    fetch: useApi(getOrganisation),
    update: useApi(updateOrganisationGroups),
  })

  const fetchResponse = api.requests.fetch.getResponse()
  const saveResponse = api.requests.update.getResponse()

  const initialValues = groupId
    ? { id: groupId, organisationId, name: fetchResponse.data?.name ?? '' }
    : {
      id: uuid(), organisationId, name: '', vehicles: [],
    }
  const [formData, setField] = useFormState(initialValues)

  useEffect(() => {
    if (organisationId) {
      api.requests.fetch.sendRequest({ organisationId })
    }
    return () => {
      api.requests.fetch.cleanup()
    }
  }, [])

  const formId = 'organisation-group-details-form'
  const pageTitle = groupId ? 'Edit group' : 'New group'
  usePageTitle(pageTitle)

  if (fetchResponse.success && !fetchResponse.data) {
    return <NotFound />
  }

  if (saveResponse.success) {
    if (isAdmin) return <Navigate to={`/${user.getRoleForRoute()}/organisations/${organisationId}`} />
    return <Navigate to={`/organisations/${organisationId}`} />
  }

  /**
   * Create or update a gate using the API
   */
  const save = (group: any) => {
    const groups = JSON.parse(fetchResponse.data.groups) || []
    const groupIdxToUpdate = groups.findIndex((g: any) => g.id === group.id)
    if (groupIdxToUpdate !== -1) {
      groups[groupIdxToUpdate] = group
    } else {
      groups.push(group)
    }
    const organisationUpdate = { organisationId, groups: JSON.stringify(groups) }
    api.requests.update.sendRequest(organisationUpdate)
  }

  /**
   * Return to the organisation list screen
   */
  const cancel = () => {
    if (isAdmin) navigate(`/${user.getRoleForRoute()}/organisations/${organisationId}`)
    else navigate(`/organisations/${organisationId}`)
  }

  return (
    <Panel
      header={(
        <PanelHeader
          loading={false}
          editing
          formId={formId}
          onCancel={cancel}
          header={pageTitle}
        />
      )}
    >
      {(fetchResponse.data === null || fetchResponse.loading || saveResponse.loading)
        ? <Loader center content="Loading..." />
        : (
          <OrganisationGroupForm
            formId={formId}
            formValue={formData}
            initialData={fetchResponse.data}
            onChange={setField}
            onSubmit={save}
            error={null}
            mode={groupId ? 'edit' : 'new'}
          />
        )}
    </Panel>
  )
}

export { OrganisationGroupDetails }
